import { CouponSystem, InvoiceType, CreditCardPaymentType } from '@/models/Enums';
import moment from 'moment';
import { DateConstants } from '@/models/Constants';
export class BusinessClientsFilter {
    pageIndex: number;
    orderBy: string;
    asc: boolean;
    keywords: string;
    id: string;
    name: string;
    type: number | null;
    balanceOfReceipts: boolean;
    balanceOfInvoice: boolean;
    balanceDue: boolean;

    constructor() {
        this.pageIndex = 0;
        this.asc = true;
        this.orderBy = 'Id';
        this.keywords = '';
        this.id = '';
        this.name = '';
        this.type = null;
        this.balanceOfReceipts = false;
        this.balanceOfInvoice = false;
        this.balanceDue = false;
    }
}

export class BusinessClient {
    id = 0;
    name = '';
    phone1 = '';
    phone2: string | null = null;
    accountingSystemCode = '';
    businessClientTypeId = '';
    businessClientTypeName = '';
    officialClubId: string | null = null;
    countryId = '';
    countryName = '';
    cityId: string | null = null;
    cityName = '';
    logoUrl = '';
    logo = '';
    address = '';
    email = '';
    fax = '';
    website = '';
    vatNumber = '';
    instruction = '';
    businessClientContacts: BusinessClientContact[] = [];
    businessClientDiscountCombinations: BusinessClientDiscountCombinations[] = [];
    businessClientPhotoInstructions: BusinessClientPhotoInstruction[] = [];
    couponSystemPassword = '';
    couponSystemTerminal = '';
    couponSystemUrl = '';
    couponSystemUsername = '';
    couponSystem: CouponSystem | null = null;
    couponSystemOrganizationId = '';
    companyLogoUrl = '';
    totalInvoicesLeftToBind = 0;
    totalReceipts = 0;
    totalInvoice = 0;
    totalOrders = 0;
    balance = 0;
    active = true;
}

export class BusinessClientContact {
    id = 0;
    firstName = '';
    lastName = '';
    mobile = '';
    phone = '';
    email = '';
    role = '';
    receiveEmailNotifications = false;
}

export class BusinessClientDiscountCombinations {
    id = 0;
    channelId = '';
    channelName = '';
    priceTypeId = '';
    priceTypeName = '';
    discount = 0;
    currencyId = '';
    currencySymbol = '';
}

export class ClientContactError {
    index = 0;
    firstName = false;
    lastName = false;
    mobile = false;
    officePhone = false;
    email = false;
}

export class BusinessClientOrderProduct {
    orderId = 0;
    hashOrderId = '';
    customerName = '';
    couponId = 0;
    couponName = '';
    productId = 0;
    productName = '';
    customerTypeName = '';
    discountAmountLocalCurrency = 0;
    businessClientTotalAmount = 0;
    localCurrencySymbol = '';
    discountPercent = 0;
    invoices = 0;
    paid = 0;
    balance = 0;
    createdDate = '';
}

export class BusinessClientPhotoInstruction {
    id = '';
    url = '';
    logo = '';
}

export class BusinessOrderProductsFilter {
    businessClientId: number;
    productId: string | null;
    orderNumber: string | null;
    customerName: string;
    couponId: string | null;
    pageIndex: number;
    orderBy: string;
    asc: boolean;
    createDateStart: string | null;
    createDateEnd: string | null;
    keyword: string;

    constructor(_businessClientId: number) {
        this.businessClientId = _businessClientId;
        this.productId = null;
        this.orderNumber = null;
        this.customerName = '';
        this.couponId = null;
        this.pageIndex = 0;
        this.orderBy = 'OrderId';
        this.asc = false;
        this.createDateStart = null;
        this.createDateEnd = null;
        this.keyword = '';
    }
}

export class BusinessClientAccount {
    documentType = 0;
    customerName = '';
    documentNumber = '';
    total = 0;
    id = '';
    businessClientId = 0;
    pdfUrl = '';
    pdfUrlWithInvoices = '';
    currencySymbol = '';
    localCurrencySymbol = '';
    createdDate = '';
    creator = '';
    invoiceType: InvoiceType | null = null;
    debit: number | null = null;
    credit: number | null = null;
}

export class BusinessAccountingFilter {
    businessClientId: number;
    pageIndex: number;
    orderBy: string;
    asc: boolean;
    keyword: string;
    documentNumber: number | null;
    customer: string;
    orderNumber: number | null;
    userId: number | null;
    documentType: number | null;
    createDateStart: string | null;
    createDateEnd: string | null;

    constructor(_businessClientId: number) {
        this.businessClientId = _businessClientId;
        this.pageIndex = 0;
        this.orderBy = 'DocumentNumber';
        this.asc = false;
        this.keyword = '';
        this.documentNumber = null;
        this.customer = '';
        this.orderNumber = null;
        this.userId = null;
        this.documentType = null;
        this.createDateStart = null;
        this.createDateEnd = null;
    }
}
export class BusinessInvoiceCustomersFilter {
    businessClientId: number;
    productId: string | null;
    orderNumber: number | null;
    couponId: string | null;
    createDateStart: string | null;
    createDateEnd: string | null;
    arrivalDateStart: string | null;
    arrivalDateEnd: string | null;

    constructor(_businessClientId: number) {
        this.businessClientId = _businessClientId;
        this.orderNumber = null;
        this.productId = null;
        this.couponId = null;
        this.createDateStart = null;
        this.createDateEnd = null;
        this.arrivalDateStart = null;
        this.arrivalDateEnd = null;
    }
}
export class BusinessReceiptInvoicesFilter {
    invoiceNumber: number | null;
    createDateStart: string | null;
    createDateEnd: string | null;

    constructor() {
        this.invoiceNumber = null;
        this.createDateStart = null;
        this.createDateEnd = null;
    }
}

export class BusinessCreditCardPelecardPayment {
    fakeId: string;
    total: number | null;
    currencyId: string;
    currencyRate: number;
    numberOfPayments: number;
    creditCardPaymentType: CreditCardPaymentType;
    transactionId: number;
    lastFourDigits: string;
    isTerminalPaymentOnline: boolean;

    constructor() {
        this.fakeId = '';
        this.total = null;
        this.currencyId = '';
        this.currencyRate = 1;
        this.numberOfPayments = 1;
        this.creditCardPaymentType = CreditCardPaymentType.Regular;
        this.transactionId = 0;
        this.lastFourDigits = '';
        this.isTerminalPaymentOnline = false;
    }
}

export class BusinessCashPayment {
    id: string;
    fakeId: string;
    total: number | null;
    currencyId: string;
    currencyRate: number;

    constructor() {
        this.id = '';
        this.fakeId = '';
        this.total = null;
        this.currencyId = '';
        this.currencyRate = 1;
    }
}

export class BusinessCheckPayment {
    id: string;
    fakeId: string;
    total: number | null;
    currencyId: string;
    currencyRate: number;
    owner: string;
    bankId: string;
    bankName: string;
    bankBranchNumber: number | null;
    accountNumber: number | string;
    checkNumber: number | string;
    valueDate: string;

    constructor() {
        this.id = '';
        this.fakeId = '';
        this.total = null;
        this.currencyId = '';
        this.currencyRate = 1;
        this.owner = '';
        this.bankId = '';
        this.bankName = '';
        this.bankBranchNumber = null;
        this.accountNumber = '';
        this.checkNumber = '';
        this.valueDate = moment().format(DateConstants.DATE_FORMAT_SHOW);
    }
}

export class BusinessBankTransferPayment {
    id: string;
    fakeId: string;
    total: number | null;
    currencyId: string;
    currencyRate: number;
    bankId: string | null;
    bankName: string;
    bankBranchNumber: number | null;
    accountNumber: number | string;
    valueDate: string;
    providerAccountId: string;
    providerAccountName: string;
    referenceNumber: string;
    constructor() {
        this.id = '';
        this.fakeId = '';
        this.total = null;
        this.currencyId = '';
        this.currencyRate = 1;
        this.bankId = null;
        this.bankName = '';
        this.bankBranchNumber = null;
        this.accountNumber = '';
        this.valueDate = moment().format(DateConstants.DATE_FORMAT_SHOW);
        this.providerAccountId = '';
        this.providerAccountName = '';
        this.referenceNumber = '';
    }
}
