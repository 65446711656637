
import { nextTick, ref, onMounted, defineComponent, computed } from 'vue';
import { BussinesClientByNewOrderObject, PriceType } from '@/models/Interfaces';
import { Customer, OrderDetails } from '@/models/Order';
import { api } from '@/services/Api';
import router from '@/router';
import swal from 'sweetalert2';
import Multiselect from '@vueform/multiselect';
import { useI18n } from 'vue-i18n';
import Q from 'q';
import mixin from '@/mixin';
import { generalStore } from '@/store';

export default defineComponent({
    name: 'EditOrder',
    props: {
        orderId: {
            type: String,
            required: true
        }
    },
    components: { Multiselect },
    async setup(props: any) {
        const customer = ref<Customer>(new Customer());
        const order = ref<OrderDetails>(new OrderDetails());
        const clients = ref<BussinesClientByNewOrderObject[]>([]);
        const businessClientExist = ref(false);
        const showPage = ref(false);
        const priceTypes = ref<PriceType[]>([]);
        function close() {
            router.go(-1);
        }
        const isGroupPrice = computed(() => {
            return mixin.methods.isGroupPrice(order.value.priceTypeId, priceTypes.value);
        });
        const groupPriceTypeName = computed(() => {
            return isGroupPrice.value ? order.value.priceType.name : '';
        });
        const { t } = useI18n();
        async function submitForm(event: any) {
            if (!event.target.checkValidity()) {
                event.preventDefault();
                event.stopPropagation();
                return;
            }
            swal.showLoading();
            await nextTick();
            const businessClientId = !businessClientExist.value ? order.value.businessClientId : null;
            const apiResult = await api.EditOrder(props.orderId, customer.value, businessClientId);
            await Q.delay(400);
            if (apiResult.hasValidationError) {
                const message = apiResult
                    .validationErrorsList()
                    ?.map(x => `Field '${x.key}': ${x.msg}`)
                    ?.join('\n');
                swal.fire({
                    icon: 'error',
                    text: message
                });
                return;
            }
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }

            close();
        }

        async function onChangeSubscription(event: any) {
            event.preventDefault();

            const originalValue = customer.value!.subscribe;
            const newValue = !originalValue;

            const title = newValue
                ? t('order.subscribe-dialog-true')
                : t('order.subscribe-dialog-false');

            const swalResult = await swal.fire({
                icon: 'warning',
                title: title,
                customClass: {
                    confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5',
                },
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: t('button.yes'),
                cancelButtonText: t('button.no')
            });

            if (swalResult.isConfirmed) {
                customer.value!.subscribe = newValue;
            } else {
                event.target.checked = originalValue;
            }
        }

        async function onBussinessClientChange(businessClientId: number | null) {
            if (businessClientId == null) {
                return;
            }
            swal.showLoading();
            const apiPromise = await api.ExistCouponsForThisOrder(props.orderId, businessClientId);
            await Q.delay(400);
            const apiResult = await apiPromise;
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }
            if (apiResult.data?.exist) {
                swal.fire({
                    icon: 'warning',
                    title: t('alert.order.potential-coupon'),
                    confirmButtonText: t('button.close')
                });
            } else {
                swal.close();
            }
        }
        const onMountedCall = async () => {
            if (!props.orderId) {
                router.push({ name: 'orders', params: { newSearch: '1' } });
                return;
            }
            swal.showLoading();
            const apiResult = await api.GetOrder(props.orderId);
            await Q.delay(400);
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }
            if (!apiResult.data) {
                router.push({ name: 'order', params: { id: props.orderId } });
                return;
            }
            order.value = apiResult.data.order;
            customer.value = apiResult.data.order.leadCustomer;
            priceTypes.value = await generalStore.dispatch('getPriceTypes');
            clients.value = await generalStore.dispatch('loadBusinessClients');
            businessClientExist.value = order.value.businessClientId != null;
            showPage.value = true;
            if (swal.isVisible() && swal.isLoading()) {
                swal.close();
            }
        };
        onMounted(onMountedCall);
        return {
            close,
            submitForm,
            onBussinessClientChange,
            customer,
            groupPriceTypeName,
            order,
            clients,
            businessClientExist,
            showPage,
            onChangeSubscription
        };
    }
});
