
import { defineComponent, ref } from 'vue';

export default defineComponent({
    props: {
        currencySign: {
            type: String,
            default: ''
        },
        priceTotalWithOutDiscount: {
            type: Number,
            default: 0
        },
        discountTotal: {
            type: Number,
            default: 0
        },
        price: {
            type: Number,
            default: 0
        },
        customerTotalToPay: {
            type: Number,
            default: 0
        },
        businessClientTotalToPay: {
            type: Number,
            default: 0
        }
    },
    async setup() {
        return {};
    }
});
