import {
    StepNumber,
    NotificationType,
    OrderStatus,
    ArrivalConfirmation,
    ProductInfoType,
    ReceiptPaymentSteps,
    DocumentType,
    ExportRequestStatus,
    ExternalAccountingExportDocumentType,
    InvoiceType,
    OrderSearchType,
    CrmCustomerSaveChanges
} from '@/models/Enums';
import { ProductFilter, Product, ProductPrice, ProductListItem, ProductTags, OrdersNotifications } from '@/models/Product';
import {
    OrderProductDetails,
    Slot,
    OrderProduct,
    OrderDetails,
    MyOrder,
    OrdersFilter,
    OrderMessage,
    RevenueByCurrency,
    Ticket,
    OrderProductCustomerDetails,
    Customer as LeadCustomer,
    CustomerInfo,
    ProductToAddRequestItem,
    ProductToAddRequestCustomerItem,
    MyOrderCustomer
} from '@/models/Order';
import { ChatItem } from '@/models/ChatQuotes';
import {
    ProviderSettingsData,
    ProviderAddress,
    Coupon,
    CouponFilter,
    CustomerType,
    BulkInvoiceHistory,
    SubCoupon
} from '@/models/Provider';
import { Document } from '@/models/Document';
import { DocumentsFilter } from '@/models/Document';
import { CalendarEvent, CalendarSlot, CalendarSlotAvailability, CalendarStopSale, Dates, Day } from './Calendar';
import {
    BusinessClient,
    BusinessClientsFilter,
    ClientContactError,
    BusinessClientOrderProduct,
    BusinessClientAccount,
    BusinessAccountingFilter,
    BusinessOrderProductsFilter,
    BusinessCreditCardPelecardPayment,
    BusinessCashPayment,
    BusinessCheckPayment,
    BusinessBankTransferPayment
} from '@/models/BusinessClients';
import {
    BusinessInvoiceCustomer,
    InvoiceForReceipt,
    Receipt,
    InvoiceDocumentProduct,
    InvoiceReceipt,
    BulkInvoicesInfo
} from '@/models/Accounting';
import moment from 'moment';
import { Employee, SlotsWithNoEmployee, SlotsWithNoEmployeeFilter, EmployeeFilter } from '@/models/Employee';
import { SubCouponsFilter } from './Coupons';
import {
    CrmCustomerDetailsModel,
    CrmCustomerListModel,
    UserReminder,
    QuickReplyMessageViewModel,
    CrmCustomerMessage,
    CrmCustomerTimingChatMessage
} from '@/models/CrmCustomers';

export class GeneralState {
    permissions: number[] = [];
    currentPrice: ProductPrice = new ProductPrice();
    lang = '';
    isAuthenticated = false;
    user?: User | null = null;
    currencies: Currency[] = [];
    currencyRates: CurrencyRate[] = [];
    regions: Region[] = [];
    cities: City[] = [];
    productTypes: ProductType[] = [];
    businessClients: IdName[] = [];
    tags: Tag[] = [];
    subTags: SubTag[] = [];
    product: Product | null = new Product();
    languages: Language[] = [];
    order: OrderDetails | null = null;
    predefinedDates: PredefinedDate[] = [];
    orderFilter: OrdersFilter = new OrdersFilter();
    documentsFilter: DocumentsFilter = new DocumentsFilter();
    businessAccountingFilter: BusinessAccountingFilter | null = null;
    businessOrderProductsFilter: BusinessOrderProductsFilter | null = null;
    selectedWeekDays: string[] = [];
    defaultPriceTypeId = '';
    countryCode = '';
    countryLatitude = 0;
    countryLongitude = 0;
    googleMapsApiKey = '';
    productLastOpenedBlock = 0;
    selectedPriceTypeIdFilter = '';
    selectedProductIdFilter = '';
    productsCounts = 0;
    channels: IdName[] = [];
    priceTypes: PriceType[] = [];
    products: Product[] = [];
    calendarExactDay: Day = new Day();
    calendarExactMonth: Dates = new Dates();
    catalogFilter: ProductFilter = new ProductFilter();
    editingProduct: Product = new Product();
    ordersNotifications: OrdersNotifications = new OrdersNotifications();
    subscriptions: SubsctiptionType[] = [];
    isNotificationMenuExpand = false;
    vatPercent = 0;
    multiplyVAT = 0;
    multiplyExcludingVAT = 0;
    defaultChannelId = '';
    envIsProduction = false;
    businessClientTypes: IdName[] = [];
    officialClubs: IdName[] = [];
    countries: IdName[] = [];
    businessClientsFilter: BusinessClientsFilter = new BusinessClientsFilter();
    myProducts: IdText[] = [];
    myCoupons: IdTextClient[] = [];
    banks: IdName[] = [];
    bankBranches: BankBranch[] = [];
    couponFilter: CouponFilter = new CouponFilter();
    users: IdName[] = [];
    providerAccounts: ProviderAccountObject[] = [];
    localCurrencyId = '';
    localCurrencySymbol = '';
    customerToChangeDate: CustomerToChangeDate | null = null;
    modalAlerts: ModalAlert[] = [];
    tagsForEmployees: IdName[] = [];
    productsForEmployees: IdName[] = [];
    employee: Employee = new Employee();
    employees: Employee[] = [];
    employeesFilter: EmployeeFilter = new EmployeeFilter();
    defaultProviderSettings: DefaultProviderSettings | null = null;
    lastCreateBulkInvoicesTaskFailed = false;
    lastCreateBulkInvoicesTaskId: string | null = null;
    lastCreateBulkInvoicesEntityId: string | null = null;
    subCouponsFilter: SubCouponsFilter | null = null;
    showRemindersSnoozePopup = false;
    userReminders: UserReminder[] = [];
    leadTypes: IdName[] = [];
}

export interface LoginResponse {
    token: string;
}

export interface NotificationsRestrictions {
    productId: number;
    unfinishedProductSlotEmpty: boolean;
    unfinishedProductPriceEmpty: boolean;
    unfinishedProductSlotLess: boolean;
    unfinishedProductPriceLess: boolean;
    unfinishedProductNoPhoto: boolean;
}

export interface InitResponse {
    permissions: number[];
    lang: string;
    isAuthenticated: boolean;
    user: User;
    currencies: Currency[];
    currencyRates: CurrencyRate[];
    defaultPriceTypeId: string;
    countryCode: string;
    countryLatitude: number;
    countryLongitude: number;
    googleMapsApiKey: string;
    productsCount: number;
    subscriptions: SubsctiptionType[];
    vatPercent: number;
    multiplyExcludingVAT: number;
    multiplyVAT: number;
    defaultChannelId: string;
    envIsProduction: boolean;
    localCurrencyId: string;
    localCurrencySymbol: string;
    modalAlerts: ModalAlert[];
    defaultProviderSettings: DefaultProviderSettings;
}

export interface DefaultProviderSettings {
    defaultProviderSettingsSmsSenderName: string;
    defaultProviderSettingsEmailSenderName: string;
    defaultProviderSettingsPerfomaInvoiceTitle: string;
    defaultProviderSettingsCanExportPerfomaInvoices: boolean;
    bulkInvoiceType: InvoiceType
}

export interface ModalAlert {
    id: number;
    status: number;
    buttonUrl: string;
}

export interface SubsctiptionType {
    id: number;
    name: string;
    availableProducts: number;
}

export interface User {
    id: string;
    clientId: string;
    fullName: string;
    email: string;
    active: boolean;
}

export interface ChangeLanguage {
    lang: string;
}

export interface PredefinedDate {
    id: string;
    name: string;
    fromDate: string;
    toDate: string;
}

export interface Tag {
    id: string;
    name: string;
    subTags: SubTag[];
}

export interface SubTag {
    id: string;
    name: string;
    parentTagId: string;
}

export interface BankBranch {
    id: string;
    name: string;
    bankId: string;
}

export interface Account {
    accountNumber: string;
}

export interface AddProductStep {
    number: StepNumber;
    subNumber: StepNumber;
    name: string;
}

export interface CalendarSlotResponse {
    slots: CalendarSlot[];
}
export interface CalendarSlotSaveResponse {
    slot: CalendarSlot;
}
export interface CalendarAvailabilityResponse {
    availability: CalendarSlotAvailability[];
}
export interface CalendarEventsResponse {
    events: CalendarEvent[];
}
export interface CalendarEventSaveResponse {
    event: CalendarEvent;
}
export interface CalendarStopSaleSaveResponse {
    stopSales: CalendarStopSale[];
    deletedSlotIds: number[];
}

export interface Notification {
    id: string;
    orderId: string;
    sourceId: string;
    message: string;
    createdDate: Date;
    link: string;
    type: NotificationType;
    customerFullName: string;
    customerMobile: string;
    firstProductName: string;
    orderNumber: string;
    orderStatus: OrderStatus;
    orderTotal: number;
    orderCurrencyId: number;
    new: boolean;
    opened: boolean;
    replyMessage: string;
    indexUserId: string;
    indexUserName: string;
    orderQuoteId: number;
    productId: string;
    chatMessageId: string;
    orderProductStartDate: string;
    slotStartTime: string;
    customerTypes: MyOrderCustomer[];
}

export interface Region {
    id: string;
    name: string;
    countryId: string;
}

export interface ProductRegion {
    id: string;
    productId: string;
    regionId: string;
    regionName?: string;
}
export interface City {
    id: string;
    name: string;
    regionId: string;
}

export interface ProductType {
    id: string;
    name: string;
}
export interface ProductImage {
    id: string;
    path: string;
    pathSmall: string;
    pathMedium: string;
    pathLarge: string;
    name: string;
    isMainImage: boolean;
    translations: FileTranslation[];
}

export interface ResponsiblePersonImage {
    path: string;
}

export interface ProductDocument {
    id: string;
    path: string;
    name: string;
    translations: FileTranslation[];
}

export interface ProductVideo {
    id: string;
    path: string;
    productId: string;
}

export interface ProductFAQ {
    id: string;
    question: string;
    answer: string;
    translations: Translation[];
}
export interface ProductMoreInfo {
    id: string;
    title: string;
    description: string;
    type: ProductInfoType;
    translations: Translation[];
}

export interface Currency {
    id: string;
    name: string;
    culture: string;
    symbol: string;
}

export interface CurrencyRate {
    id: string;
    currencyId: string;
    rate: number;
    createDate: string | null;
    startDate: string;
}
export interface ProviderCurrencyRate {
    id: string;
    currencyId: string;
    rate: number | null;
    createDate: string | null;
    startDate: string;
}

export interface Language {
    id: string;
    name: string;
    twoLetterIsoLanguageName: string;
}
export interface ProductTranslation {
    name: string;
    shortDescription: string;
    fullDescription: string;
    fullDescriptionIndex: string;
    arrivalInstructions: string;
    parkingInstructions: string;
    language: Language;
    responsiblePersonDescription: string;
    policy: string;
    extraInfo: string;
}

export interface FileTranslation {
    description: string;
    entityId: string;
    id: string;
    languageId: string;
    name: string;
}

export interface Translation {
    name: string;
    description: string;
    entityId: string;
    id: string;
    languageId: string;
}

export interface PriceType {
    id: string;
    text: string;
    category: number;
    originName: string;
}

export interface IdText {
    id: string;
    text: string;
}

export interface OnSelectProduct {
    id: string;
    text: string;
    imageUrl: string;
    withCoupon: boolean;
}

export interface IdTextClient {
    id: string;
    text: string;
    clientId: number;
}

export interface Customer {
    id: string;
    name: string;
    quantity: string;
    price: string;
    priceForOne: string;
}

export interface IdName {
    id: string;
    name: string;
}
export interface ProviderAccountObject {
    id: string;
    name: string;
    currencies: string[];
}
export interface IntIdName {
    id: number;
    name: string;
}
export interface CustomerToChangeDate {
    productId: string;
    customerId: string;
    date: string;
    priceTypeId: string;
    slot: Slot;
    couponId: string | null;
}

export interface IdNamePrice {
    id: string;
    name: string;
    priceForOne: number;
}

export interface IdQty {
    id: string;
    quantity: number;
}
export interface SaveOrderObject {
    evenIfNotAvailable: boolean;
    priceTypeId: string;
    leadCustomer: LeadCustomer;
    discountAmount: number;
    currencyId: string;
    products: OrderProductDetails[];
    remarks: string;
    channelId: string;
    businessClientId: number | null;
    crmCustomerId: string | null;
}

export interface ApiResponseBase {
    error: string;
    errorCode: number;
}

export interface GetProductSlotsResponse extends ApiResponseBase {
    priceId: string;
    priceName: string;
    slots: Slot[];
}
export interface CalculateProductCustomersRequest {
    priceTypeId: string;
    productId: string;
    slotId: string;
    date: string | null;
    dateTime: string;
    customers: ProductToAddRequestCustomerItem[] | null;
}

export interface CalculateProductCustomersResponse extends ApiResponseBase {
    currency: string;
    currencySign: string;
    customerTotalToPay: number;
    businessClientTotalToPay: number;
    price: number;
    discountTotal: number;
    priceTotalWithOutDiscount: number;
    customers: OrderProductCustomerDetails[];
    priceForOne: number;
    name: string;
    date: string;
    slot: Slot;
    quantity: number;
    isAvailable: boolean;
    notAvailableCustomerTypeIds: string[];
}
export interface CalculateOrderResponse extends ApiResponseBase {
    currencySign: string;
    currency: string;
    discountAmount: number;
    discountPercent: number;
    priceTotal: number;
    commissionPercent: number;
    totalToPay: number;
    priceWithDiscount: number;
    products: OrderProductDetails[];
    commissionTotal: number;
    net: number;
    paid: number;
    ballance: number;
    gross: number;
    businessClientTotalAmount: number;
}
export interface GetProductCustomersResponse extends ApiResponseBase {
    customers: CustomerInfo[];
}
export interface SaveProductImageResponse extends ApiResponseBase {
    productImage: ProductImage;
}
export interface TicketImageResponse extends ApiResponseBase {
    image: ProductImage;
}
export interface SaveResponsiblePersonImageResponse extends ApiResponseBase {
    responsiblePersonImage: ResponsiblePersonImage;
}
export interface SaveOrderResponse extends ApiResponseBase {
    orderId: string;
    orderNumber: number;
}
export interface GetOrderResponse extends ApiResponseBase {
    order: OrderDetails;
}
export interface GetOrdersResponse extends ApiResponseBase {
    orders: OrderDetails[];
}
export interface FindOrdersByScanResponse extends ApiResponseBase {
    orders: OrderDetails[];
    searchBy: OrderSearchType;
    value: string;
}
export interface GetOrderLinkResponse extends ApiResponseBase {
    link: string;
}
export interface TaskResponse extends ApiResponseBase {
    taskId: string;
}
export interface AwaitTaskResponse extends ApiResponseBase {
    status: number;
    url: string;
}
export interface GetMyOrderResponse extends ApiResponseBase {
    rows: MyOrder[];
    revenueByCurrencies: RevenueByCurrency[];
    hasNext: boolean;
    total: number;
    totalFiltered: number;
}
export interface GetPaymentsAccountTransactionsResponse extends ApiResponseBase {
    rows: PaymentsAccountTransaction[];
    hasNext: boolean;
    total: number;
    totalFiltered: number;
    totalAmountLocalCurrency: number;
}
export interface GetSlotsWithNoEmployee extends ApiResponseBase {
    rows: SlotsWithNoEmployee[];
    hasNext: boolean;
    total: number;
    totalFiltered: number;
}

export interface GetOrderRemarksResponse extends ApiResponseBase {
    remarks: string;
}
export interface SaveOrderRemarksResponse extends ApiResponseBase {
    remarks: string;
}
export interface GetProductsResponse extends ApiResponseBase {
    total: number;
    products: Product[];
}

export interface GetEmployeesResponse extends ApiResponseBase {
    total: number;
    employees: Employee[];
}

export interface GetTagsProducts extends ApiResponseBase {
    tags: IdName[];
    products: IdName[];
}

export interface GetEmployeesResponse extends ApiResponseBase {
    total: number;
    employees: Employee[];
}

export interface GetTagsProducts extends ApiResponseBase {
    tags: IdName[];
    products: IdName[];
}

export interface GetRefProductsResponse extends ApiResponseBase {
    products: ProductListItem[];
    notActiveProducts: IdName[];
}

export interface GetProductsListResponse extends ApiResponseBase {
    products: ProductListItem[];
}
export interface GetOrderProductDateResponse extends ApiResponseBase {
    priceTypeId: string;
    productId: string;
    date: string;
    slot: Slot;
}
export interface GetOrderMessagesResponse extends ApiResponseBase {
    messages: OrderMessage[];
    status: number;
    statusName: string;
}
export interface GetDocumentsResponse extends ApiResponseBase {
    rows: Document[];
    hasNext: boolean;
    total: number;
    totalFiltered: number;
    totalAmount: number;
    totalAmountBeforeVat: number;
    totalVat: number;
}
export interface GetDocumentOrderNumberResponse extends ApiResponseBase {
    orderNumber: string;
}
export interface GetProductPriceResponse extends ApiResponseBase {
    productPrice: ProductPrice;
}
export interface GetDocumentPdfUrlResponse extends ApiResponseBase {
    pdfUrl: string;
}
export interface ShareDocumentResponse extends ApiResponseBase {
    sended: boolean;
}
export interface ShareDocumentRequest {
    id: string;
    sendEmail: boolean;
    sendSms: boolean;
    message: string;
    emailReceiver: string;
    emailSubject: string;
    smsReceiver: string;
    lang: string;
    timezone: number;
    includeDocs: boolean;
}
export interface GetDocumentShareDataResponse extends ApiResponseBase {
    emailReceiver: string;
    emailSubject: string;
    smsReceiver: string | null;
    docNumber: string;
    docType: DocumentType;
    customerName: string;
    isBusiness: boolean;
    isCredit: boolean;
    providerDocumentTypeName: string;
}

export class DateRange {
    fromDate: Date | null = null;
    toDate: Date | null = null;
}
export class MessageTemplateFilter {
    keywords = '';
    pageIndex = 0;
    messageText = '';
    fromDate: string | null = null;
    toDate: string | null = null;
}

export class PaymentsAccountTransactionsFilter {
    keywords = '';
    pageIndex = 0;
    paymentType: number | null = null;
    totalFrom: string | number | null = null;
    totalTo: string | number | null = null;
    currencyId: string | null = null;
    paymentDateStart: string | null = null;
    paymentDateEnd: string | null = null;
    receiptNumber: string | null = '';
    receiptCreateDateEnd: string | null = null;
    receiptCreateDateStart: string | null = null;
}

export class PaymentsAccountTransaction {
    id = '';
    paymentType = 0;
    paymentDate = '';
    total = 0;
    paymentCurrencySymbol = '';
    receiptNumber = '';
    receiptCreateDate = '';
    orderNumber = '';
    to = '';
    isInvoiceRecepit = false;
    receiptId = '';
    orderId = '';
    additionalInfo = '';
    businessClientId: number | null = null;
}

export class SmsManagementFilter {
    keywords = '';
    pageIndex = 0;
    messageText = '';
    senderName = '';
    fromDate: string | null = null;
    toDate: string | null = null;
}

export class EmailManagementFilter {
    keywords = '';
    pageIndex = 0;
    messageText = '';
    fromEmail = '';
    subject = '';
    fromDate: string | null = null;
    toDate: string | null = null;
}

// Report START
export interface ReportRow {
    quantity: number;
    amount: number;
    orders: number;
    currencySign: string;
}
export interface TimelineReportRow extends ReportRow {
    date: string;
}
export interface FullReportRow extends ReportRow {
    id: number;
    name: string;
}

export interface DashboardResult extends ApiResponseBase {
    generalReport: ReportRow;
    productsReport: FullReportRow[];
    priceTypesReport: FullReportRow[];
    timelines: TimelineReportRow[];
}

export enum Permissions {
    ViewOrders = 1,
    EditOrders = 2,
    ChangeOrderStatus = 3,
    CreateReceipts = 4,
    CreateInvoices = 5,
    CreateCreditInvoices = 6,
    GenerateLinkOfPersonalAreaToOrder = 7,
    DocumentViewSearch = 8,
    DocumentShare = 9,
    ReportsView = 10,
    ProductsViewSearch = 11,
    CalendarViewFilter = 12,
    CalendarStopSale = 13,
    EditProducts = 14,
    CreateProducts = 15,
    ViewProviderSettings = 16,
    EditProviderSettings = 17,
    CreateOrders = 27,
    Cashier = 28,
    CashierReservedOrders = 29,
    CashierSellToPrivate = 30,
    CashierSellToBusiness = 31,
    CashierClubsVouchers = 32,
    CashierChangeRate = 33,
    ChangeUnitPrice = 34,
    CashierChangeDiscount = 35,
    CashierEditSlot = 36,
    CreatePerfomaInvoices = 37,
    CanCancelExistingReceipt = 38,
    CreateBulkInvoices = 39,
    CreateChangeOrderInPast = 40,
    CreateChangeSlotInPast = 41
}
export enum UserModalAlertTypes {
    None = 0,
    SlotNotFound = 1
}
export interface ChangeProductRegionsResponse {
    productRegions: ProductRegion[];
}
export interface ChangeProductTagsResponse {
    productTags: ProductTags[];
    productExcludedTags: ProductTags[];
    productIncludedTags: ProductTags[];
}
export interface MarkOrderMessagesAsReadResponse {
    orderId: string;
    userId: string;
    messageId: string;
}
// Report END

// IndexStatistics START
export interface IndexProductStatistics {
    id: number;
    name: string;
    showContactsClicks: number;
    productPageVisits: number;
    resultPageDisplays: number;
}

export interface ItemPerProduct {
    id: number;
    name: string;
    totalCount: number;
}

export interface TimelineIndexStatistics {
    id: number;
    dates: string[];
    productPageVisitsValues: number[];
    showContactsClicksValues: number[];
    productDisplayValues: number[];
}

export interface IndexUserChat {
    name: string;
    agencyName: string;
}

export interface IndexStatisticsResult extends ApiResponseBase {
    numberOfQuotes: number;
    numberOfChats: number;
    productsStatistics: IndexProductStatistics[];
    productsStatisticsTimelines: TimelineIndexStatistics[];
    indexUsersChats: IndexUserChat[];
    chatsPerProducts: ItemPerProduct[];
    quotesPerProducts: ItemPerProduct[];
}

// IndexStatistics END

export interface GetProviderSettingsResponse extends ApiResponseBase {
    provider: ProviderSettingsData;
}

export interface SaveProviderResponse extends ApiResponseBase {
    providerId: number;
}

export interface GetProviderAddressResponse extends ApiResponseBase {
    address: ProviderAddress;
}
export interface SettingsProviderUsers {
    active: boolean;
    fullName: string;
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
    profileId: number | null;
    userName: string;
    password: string;
    createdAt: string | null;
    providerId: number | null;
}

export interface GetUsersByFilterResponse extends ApiResponseBase {
    users: SettingsProviderUsers[];
}

export interface ProviderProfile {
    id: string;
    active: boolean;
    description: string;
    createdAt: string;
    permissions: number[];
    priceTypes: string[];
    products: string[];
    cashierMaxDicountPercent: number | null;
    cashierMaxChecks: number | null;
}

export interface SettingsProviderUser {
    id: string;
    active: boolean;
    fullName: string;
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
    profileId: number | null;
    userName: string;
    password: string;
    createdAt: string | null;
}

export interface GetUserDataResponse extends ApiResponseBase {
    user: SettingsProviderUser;
}

export interface SaveUserResponse extends ApiResponseBase {
    validationErrors: string[];
}

export interface AverageDurationTime {
    id: number;
    name: string;
}

export interface UpdateArrivalConfirmationRequest {
    orderId: string;
    arrivalConfirmation: ArrivalConfirmation;
}

export interface UpdateArrivalConfirmationsRequest {
    orderIds: string[];
    arrivalConfirmation: ArrivalConfirmation;
}

export interface CalculateCustomersResponse {
    notAvailableCustomerTypeIds: string[];
    isAvailable: boolean | null;
    currencyId: string;
}

export interface QtyReportGetResponse {
    customers: QtyReportItem[];
}

export interface QtyReportItem {
    name: string;
    qty: number;
}
export class RepliedNotification {
    id: string;
    repliedMessages: string[];

    constructor() {
        this.id = '';
        this.repliedMessages = [];
    }
}
export interface GetProductFAQItemResponse {
    item: ProductFAQ;
}
export interface GetBusinessClientsResponse {
    rows: BusinessClient[];
    hasNext: boolean;
    total: number;
    totalFiltered: number;
}
export interface GetBussinesClientsByNewOrderResponse {
    businessClients: BussinesClientByNewOrderObject[];
}
export interface BussinesClientByNewOrderObject {
    id: number;
    name: string;
    email: string;
    mobile: string;
    contactFirstName: string;
    contactLastName: string;
}

export interface GetBusinessClientResponse {
    businessClient: BusinessClient;
    amountOrder: number;
    totalInvoices: number;
    totalAllReceipts: number;
    totalBindedReceipts: number;
    totaInvoiceslLeftToBind: number;
    balance: number;
    currencySign: string;
}

export interface SaveBusinessClientResponse extends ApiResponseBase {
    businessClientId: string;
    clientContactErrors: ClientContactError[];
    errorsClientDetails: string[];
}

export interface TranslationGeneral {
    name: string;
    entityId: string | number;
    id: string | number;
    languageId: string;
    languageName: string;
}
export interface ChangeSellingSettingResponse extends ApiResponseBase {
    newValue: boolean;
    ids: string[];
}
export interface GetSmsHistoryResponse extends ApiResponseBase {
    smsHistory: SmsHistory[];
}
export class SmsHistory {
    id = 0;
    mobile = '';
    message = '';
    createdAt = '';
    status = true;
}
export interface SaveProductResponse extends ApiResponseBase {
    product: Product;
    warningCode: number;
}
export interface GetChatsAndQuotesResponse {
    chats: ChatItem[];
    hasNext: boolean;
    total: number;
    totalFiltered: number;
}
export interface AgentDetails {
    id: string;
    agencyName: string;
    fullName: string;
    email: string;
    mobile: string;
}

export interface GetOrderTicketResponse extends ApiResponseBase {
    tickets: Ticket[];
}

export interface GetCouponsResponse {
    total: number;
    rows: Coupon[];
    pageIndex: number;
    hasNext: boolean;
}

export interface CouponOptions {
    priceTypes: IdText[];
    customerTypes: CustomerType[];
    currencies: IdText[];
}
export interface GetBusinessOrderProductsResponse extends ApiResponseBase {
    hasNext: boolean;
    total: number;
    totalFiltered: number;
    rows: BusinessClientOrderProduct[];
}

export interface GetBusinessAccountsResponse extends ApiResponseBase {
    hasNext: boolean;
    total: number;
    totalFiltered: number;
    rows: BusinessClientAccount[];
}
export interface GetBusinessClientCustomersForInvoiceResponse extends ApiResponseBase {
    rows: BusinessInvoiceCustomer[];
    totalAmount: number;
    localCurrencySymbol: string;
}

export interface GetBusinessClientInfoForInvoiceRequest {
    clientId: number | null;
    type: InvoiceType;
    orderId: string | null;
}

export interface GetBusinessClientInfoForInvoiceResponse extends ApiResponseBase {
    recipientName: string;
    businessClientId: number;
    todayData: string;
    includeVat: boolean;
    rows: BusinessInvoiceCustomer[];
    products: InvoiceDocumentProduct[];
    totalAmount: number;
    amountCoverByInvoces: number;
    localCurrencySymbol: string;
    currencyId: string;
    orderNumber: number;
}

export interface GetInfoForReceiptResponse extends ApiResponseBase {
    recipientName: string;
    businessClientId: number;
    createdDate: string;
    localCurrencySymbol: string;
    localCurrencyId: string;
    businessInvoices: InvoiceForReceipt[];
    orderNumber: number;
    orderCurrencySymbol: string;
    orderCurrencyId: string;
    leftToPay: number;
    orderCurrencyRate: number;
    isTerminalPaymentOnline: boolean;
    includeVat: boolean;
}

export interface CreateBusinessInvoiceResponse extends ApiResponseBase {
    id: number;
}
export interface CreateReceiptResponse extends ApiResponseBase {
    id: number;
}
export interface GetBusinessReceiptResponse extends ApiResponseBase {
    receipt: Receipt;
    localCurrencySymbol: string;
    businessInvoices: InvoiceForReceipt[];
}
export interface GetInvoiceReceiptResponse extends ApiResponseBase {
    invoiceReceipt: InvoiceReceipt;
    localCurrencySymbol: string;
}
export interface ValidateReceiptResponse extends ApiResponseBase {
    isValid: boolean;
}

export interface AddPaymentInReceiptViewModel {
    selectedStep: ReceiptPaymentSteps;
    creditCardPelecardPayment: BusinessCreditCardPelecardPayment;
    cashPayment: BusinessCashPayment;
    checkPayment: BusinessCheckPayment;
    bankTransferPayment: BusinessBankTransferPayment;
}
export interface GetBusinessInvoicePdfResponse extends ApiResponseBase {
    pdfUrl: string;
}
export interface GetBusinessReceiptPdfResponse extends ApiResponseBase {
    pdfUrl: string;
    pdfUrlWithInvoices: string;
}

export interface ExternalAccountingExportDocument {
    id: string;
    exportRequestId: string;
    documentNumber: number;
    total: number;
    documentType: ExternalAccountingExportDocumentType;
    currency: Currency;
    active: boolean;
    cancel: boolean;
}
export interface ExternalAccountingExportRequest {
    id: string;
    exportNumber: number;
    createdAt: string;
    startDate: string;
    endDate: string;
    status: ExportRequestStatus;
    exportDocuments: ExternalAccountingExportDocument[];
}
export interface OrderProductDateValidateToChange {
    orderId: string;
    productId: string;
    customerId: string;
}
export interface DeleteOrderProductCustomerResponse extends ApiResponseBase {
    order: OrderDetails;
}
export interface UpdateOrderProductCustomerRequest {
    orderId: string;
    orderProductid: string;
    orderProductCustomerId: string;
    unitPrice: number;
    discount: number;
    quantity: number;
    slotId: string;
    ignoreQty: boolean;
    discountChangedManually: boolean;
}
export interface CalculateForUpdateOrderProductCustomerRequest {
    priceTypeId: string;
    productId: string;
    orderProductCustomerId: string;
    unitPrice: number;
    discount: number;
    quantity: number;
    slotId: string;
    ignoreQty: boolean;
    discountChangedManually: boolean;
    startDateTime: string;
    couponId: string | null;
}

export interface UpdateOrderProductCustomerResponse extends ApiResponseBase {
    order: OrderDetails;
}
export interface CalculateForUpdateOrderProductCustomerResponse extends ApiResponseBase {
    unitPrice: number;
    quantity: number;
    discount: number;
    totalAmount: number;
}
export interface FilterCurrencyRateResponse extends ApiResponseBase {
    items: CurrencyRate[];
}
export interface SaveProviderCurrencyRateRequest {
    item: ProviderCurrencyRate;
}
export interface DeleteProviderCurrencyRateResponse extends ApiResponseBase {
    deleted: boolean;
}
export interface SaveProviderCurrencyRateResponse extends ApiResponseBase {
    validationError: string;
    currencyRates: CurrencyRate[];
}
export interface GetProviderCurrencyRateResponse extends ApiResponseBase {
    item: CurrencyRate;
}
export interface AddProductInExistOrderRequest {
    orderId: string;
    product: ProductToAddRequestItem;
    ignoreQty: boolean;
}

export interface VoucherDocumentResponse {
    row: Document;
}

export interface AutoPrintReceiptProviderSettingResponse {
    newValue: boolean;
}
export interface ProviderDocumentType {
    id: string;
    name: string;
    createDate: string;
}
export interface SaveNewOrderProductDateRequest {
    orderId: string;
    productId: string;
    customerId: string;
    slotId: string;
    date: string;
    ignoreQty: boolean;
    time: string;
}
export interface SlotsWithNoEmployeeFilterFromModal {
    filter: SlotsWithNoEmployeeFilter;
    dataRange: DateRange;
}

export interface AssignEmployeeFilter {
    keywords: string;
    onlyAvailable: boolean;
    noAssignmentsCurrentMonth: boolean;
    assignedOrderSlots: boolean;
}

export interface AssignEmployeeRequest {
    employeeId: string;
    slotId: string;
    orderId: string;
    startDate: string;
}

export interface GetOrderEmployeesResponse {
    employees: Employee[];
    availableByOrder: string[];
}
export interface GetEmployeesForSlot {
    employees: Employee[];
    availableEmployeeIds: string[];
    assignedEmployeeIds: string[];
}
export interface SendSmsEmailByOrderFilterResponse {
    smsCount: number;
    emailCount: number;
}
export interface ChangeSlotEmployeeIcon {
    slotId: string;
    startDate: string;
    remove: boolean;
}
export interface PelecardInitResponse {
    url: string;
    transactionId: number;
}
export interface AwaitBulkInvoicesResponse extends ApiResponseBase {
    bulkInvoiceHistoryId: string;
}
export interface GetBulkInvoicesHistoryInfo extends ApiResponseBase {
    bulkInvoicesInfo: BulkInvoicesInfo;
}
export interface GetBulkInvoiceHistories extends ApiResponseBase {
    bulkInvoiceHistories: BulkInvoiceHistory[];
    hasNext: boolean;
}

export interface ExternalCodesImportPreviewResponse {
    codes: string[];
}

export interface ExternalCodesImportResponse {
    codeCount: number;
}

export interface ExternalCodesSearchResponseItem {
    code: string;
    status: string;
}

export interface ExternalCodesSearchResponse {
    codes: ExternalCodesSearchResponseItem;
}

export interface SubCouponsFilterResult {
    pageIndex: number;
    hasNext: boolean;
    total: number;
    totalFiltered: number;
    subCouponsUsed: number;
    subCouponsReserved: number;
    rows: SubCoupon[];
}
export interface ExistCouponsForThisOrderResponse {
    exist: boolean;
}
export interface SaveCrmCustomerRequest2 {
    id: string | null;
    firstName: string;
    lastName: string;
    birthdate: string | null;
    businessClientId: number | null;
    email: string;
    mobile: string;
}
export class SaveCrmCustomerRequest {
    id: string | null = null;
    firstName = '';
    lastName = '';
    birthdate: string | null = null;
    businessClientId: number | null = null;
    email = '';
    mobile = '';
    remarks = '';
    changes: CrmCustomerSaveChanges | null = null;
}

export interface SaveCrmCustomerResponse extends ApiResponseBase {
    id: string;
    birthdate: string;
    businessClientId: number;
    email: string;
    firstName: string;
    lastName: string;
    mobile: string;
}
export interface GetCrmCustomerResponse extends ApiResponseBase {
    crmCustomer: CrmCustomerDetailsModel;
}
export interface GetCrmCustomerListResponse extends ApiResponseBase {
    crmCustomers: CrmCustomerListModel[];
    hasNext: boolean;
    total: number;
    totalFiltered: number;
}
export interface SaveCrmCustomerReminderResponse extends ApiResponseBase {
    id: string;
    name: string;
    alertDateTime: string;
    onlyForMe: boolean;
    active: boolean;
    relativeInterval: string;
}
export interface GetRelativeIntervalResponse extends ApiResponseBase {
    relativeInterval: string;
}
export interface SnoozeReminderResponse extends ApiResponseBase {
    alertDateTime: string;
    relativeInterval: string;
    reminderId: string;
}

export interface SendContractFormRequest {
    orderId: string,
    hasChangesAndCancellationsPolicy: boolean,
    hasPaymentConditions: boolean,
    hasBankAccountDetails: boolean,
    customerEmail: string,
    customerMobile: string 
}
export interface MarkCrmCustomerMessagesAsReadResponse {
    crmCustomerId: string;
    userId: string;
    messageId: string;
}
export interface GetQuickReplyMessagesResponse extends ApiResponseBase {
    quickReplyMessages: QuickReplyMessageViewModel[];
}
export interface GetCrmCustomerMessagesResponse extends ApiResponseBase {
    messages: CrmCustomerMessage[];
    timingChatMessages: CrmCustomerTimingChatMessage[];
}

export interface SaveTimingMessageResponse extends ApiResponseBase {
    id: string;
    message: string;
    scheduleDateTime: string;
    relativeInterval: string;
}

export interface SaveTimingMessageRequest {
    id: string | null;
    message: string;
    scheduleDate: string;
    scheduleTime: string;
    crmCustomerId: string;
}
