
import { defineComponent, ref, onMounted, provide, computed } from 'vue';
import { useRoute, onBeforeRouteLeave } from 'vue-router';
import { api } from '@/services/Api';
import { OrderDetails, OrderProductDetails, OrderProductCustomerDetails } from '@/models/Order';
import useOrder from '@/modules/useOrder';
import swal from 'sweetalert2';
import router from '@/router';
import Q from 'q';
import { ArrivalConfirmation, CouponDiscountType, CouponLimitationType, CouponType, OrderStatus, OrderStatusColors } from '@/models/Enums';
import moment from 'moment';
import OrderActions from '@/components/Modals/OrderActions.vue';
import { generalStore } from '@/store';
import OrderArrivalConfirmationActions from '@/components/Order/OrderArrivalConfirmationActions.vue';
import { useI18n } from 'vue-i18n';
import {
    PriceType,
    IdText,
    CustomerToChangeDate,
    BussinesClientByNewOrderObject,
    AssignEmployeeRequest,
    SendContractFormRequest
} from '@/models/Interfaces';
import mixin from '@/mixin';
import _ from 'lodash';
import OrderHeader from '@/components/Order/OrderHeader.vue';
import { Coupon } from '@/models/Provider';
import OrderDataView from '@/components/Order/OrderDataView.vue';
import OrderMobileActionsFooter from '@/components/Order/OrderMobileActionsFooter.vue';
import InputNumber from 'primevue/inputnumber';
import { UpdateOrderProductCustomerRequest, CalculateForUpdateOrderProductCustomerRequest } from '@/models/Interfaces';
import { createDebounce } from '@/helper';
import useOrderCustomers from '@/modules/useOrderCustomers';
import OverlayModal from '../Calendar/OverlayModal.vue';
import ContractFormModal from './ContractForm/ContractFormModal.vue';

export default defineComponent({
    components: {
        OrderArrivalConfirmationActions,
        OrderActions,
        OrderHeader,
        OrderDataView,
        OrderMobileActionsFooter,
        InputNumber,
        OverlayModal,
        ContractFormModal
    },
    name: 'Order',
    props: {
        id: {
            type: String,
            default: ''
        },
        isNew: {
            type: Boolean,
            default: false
        }
    },
    beforeRouteEnter(to, from, next) {
        if (from.name == 'modal-order-edit-remarks') {
            to.params.needScrollBottom = '1';
        }
        next();
    },
    async setup(props) {
        let chatTimerId = 0;
        onBeforeRouteLeave(async () => {
            if (!props.isNew) {
                clearTimeout(chatTimerId);
            }
            return true;
        });
        const clients = ref<BussinesClientByNewOrderObject[]>([]);
        const creatingBlocked = ref(false);
        const loaded = ref(false);
        const { t } = useI18n();
        const { removemillis, getMinutes, saveStoreOrder, apiCalculateOrder, apiSaveOrder } = useOrder();
        const route = useRoute();
        const order = ref<OrderDetails>(new OrderDetails());
        const { CalculateCustomers } = useOrderCustomers(order);
        const channels = ref<IdText[]>([]);
        const msgSent = ref(false);
        const messagedMarked = ref(false);
        const message = ref('');
        const isActionsExpand = ref(false);
        const actionsMenu = ref('');
        const filterType = ref<string>('ALL');
        const priceTypes = ref<PriceType[]>([]);
        const priceTypeSelect = ref();
        const businessClientSelect = ref();
        const incorrectMobile = ref(false);
        const incorrectEmail = ref(false);
        const canChangeUnitPrice = generalStore.getters.canChangeUnitPrice;
        // TODO: BOOK - 2226 remove when ready for Production
        const envIsProduction = computed(() => generalStore.state.envIsProduction);
        const orderProductExist = computed<boolean>(() => order.value.products.length > 0);
        const discountChangedManually = ref(false);
        const isGroupPrice = computed(() => {
            return mixin.methods.isGroupPrice(order.value.priceTypeId, priceTypes.value);
        });
        const orderType = computed(() => {
            return order.value.priceType.name + ', ' + order.value.channel?.name;
        });
        const showContractFormModal = ref(false);

        async function markMessagesAsRead() {
            if (order.value.messages && order.value.messages.length > 0 && messagedMarked.value == false) {
                const lastMessage = order.value.messages[0];
                const response = await api.markOrderMessageAsRead(props.id, lastMessage.id);
                if (response.error || response.errorMessage) {
                    swal.fire({
                        icon: 'error',
                        text: response.errorMessage
                    });
                    return;
                }
                messagedMarked.value = true;
            }
        }

        let getNextMessages: (showOld?: boolean) => void = () => {
            'funtion template for stupid composition api';
        };
        async function sendMsg() {
            msgSent.value = true;
            if (!message.value.trim()) {
                msgSent.value = false;
                return;
            }
            if (msgSent.value) {
                swal.showLoading();
                const apiResult = await api.SendOrderMessage(props.id, message.value);
                if (apiResult.data?.error) {
                    swal.fire({
                        icon: 'error',
                        text: apiResult.data?.error
                    });
                    return;
                }
                message.value = '';
                await getNextMessages();
                msgSent.value = false;

                const textarea = document.getElementById('textMessage');
                if (textarea) {
                    textarea.style.height = 'initial';
                }
                swal.close();
            }
        }
        provide('isActionsExpand', isActionsExpand);

        async function getMessages(showOld = false, fromId?: string, showLoading = false) {
            if (showLoading) swal.showLoading();
            const chatResult = await api.GetOrderMessages(props.id, filterType.value, fromId, showOld);
            if (chatResult.data) {
                messagedMarked.value = false;
                if (!fromId) {
                    order.value.messages = chatResult.data.messages;
                } else if (showOld) {
                    order.value.messages.push(...chatResult.data.messages);
                } else {
                    order.value.messages.unshift(...chatResult.data.messages);
                }
            }
            if (showLoading) swal.close();

            if (!showOld) {
                clearTimeout(chatTimerId);
                chatTimerId = setTimeout(getNextMessages, 30 * 1000);
            }
        }

        getNextMessages = async (showOld = false) => {
            let fromId: string | undefined = undefined;

            if (order.value.messages.length > 0)
                fromId = showOld ? order.value.messages[order.value.messages.length - 1].id : order.value.messages[0].id;
            await getMessages(showOld, fromId);
        };

        function changeChatType(type: string) {
            filterType.value = type;
            getMessages(false, undefined, true);
        }

        function formatChatDate(date: string) {
            const d = moment(date);
            return moment().isSame(d, 'day') ? d.format('HH:mm:ss') : d.format('DD/MM/YYYY HH:mm');
        }

        function EditOrder() {
            router.push({
                name: 'modal-order-edit',
                params: { orderId: props.id }
            });
        }

        swal.close();

        function actions() {
            isActionsExpand.value = true;
            if (window.innerWidth < 991) {
                actionsMenu.value = 'mobile';
                return;
            }
            actionsMenu.value = 'desktop';
        }

        function changeRemarks() {
            router.push({ name: 'modal-order-edit-remarks', params: { id: props.isNew ? '0' : props.id } });
        }

        async function changeOrderProductDateExistOrder(customer: OrderProductCustomerDetails, productId: string) {
            // validate if the product date has passed
            swal.showLoading();
            const apiPromise = api.OrderProductDateValidateToChange({
                orderId: props.id,
                productId: customer.orderProductId,
                customerId: customer.id
            });
            await Q.delay(400);
            const response = await apiPromise;

            if (response.errorMessage) {
                if (response.errorCode == 944) {
                    const swalResult = await swal.fire({
                        icon: 'info',
                        text: t('alert.orderChange.dataPassedConfirm'),
                        customClass: {
                            confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                            cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                        },
                        buttonsStyling: false,
                        showCancelButton: true,
                        confirmButtonText: t('button.yes'),
                        cancelButtonText: t('editProductPage.cancelEdit.alertCancel')
                    });
                    if (!swalResult.isConfirmed) {
                        return;
                    }
                } else {
                    swal.fire({ icon: 'error', text: response.errorMessage });
                    return;
                }
            }
            swal.close();
            const storeOrder = new OrderDetails();
            storeOrder.id = order.value.id;
            storeOrder.priceTypeId = order.value.priceTypeId;
            generalStore.commit('setOrder', storeOrder);
            router.push({
                name: 'modal-order-edit-productdate',
                params: {
                    id: props.id,
                    productId: productId,
                    orderProductId: customer.orderProductId,
                    customerId: customer.id,
                    slotId: customer.slot.id,
                    date: customer.date.substr(0, customer.date.length - 2)
                }
            });
        }
        async function changeOrderProductDate(customer: OrderProductCustomerDetails, productId: string) {
            if (!customer.editMode) {
                return;
            }
            if (props.isNew) {
                const req: CustomerToChangeDate = {
                    productId: productId,
                    customerId: customer.id,
                    date: customer.date,
                    priceTypeId: order.value.priceTypeId,
                    slot: customer.slot,
                    couponId: customer.couponId
                };
                generalStore.commit('setCustomerToChange', req);

                router.push({ name: 'modal-neworder-edit-productdate' });
            } else {
                await changeOrderProductDateExistOrder(customer, productId);
            }
        }

        function changeStatus(status: number) {
            order.value.statusId = status;
            order.value.status = OrderStatus[status];
        }

        const isArrivalConfirmationExpand = ref(false);

        function arrivalConfirmation() {
            isArrivalConfirmationExpand.value = true;
            if (window.innerWidth < 991) {
                actionsMenu.value = 'mobile';
                return;
            }
            actionsMenu.value = 'desktop';
        }

        async function onSaveArrivalConfirmation(value: ArrivalConfirmation) {
            isArrivalConfirmationExpand.value = false;
            swal.showLoading();
            const response = await api.UpdateArrivalConfirmation({ orderId: order.value.id, arrivalConfirmation: value });
            if (response.errorMessage) {
                swal.fire({ icon: 'error', text: response.errorMessage });
                return;
            }
            order.value.arrivalConfirmationId = value;
            swal.close();
        }
        function updateOrderPrice(newOrder: OrderDetails) {
            order.value.commissionPercent = newOrder.commissionPercent;
            order.value.totalDiscountAmount = newOrder.totalDiscountAmount;
            order.value.priceTotal = newOrder.priceTotal;
            order.value.totalToPay = newOrder.totalToPay;
            order.value.gross = newOrder.gross;
            order.value.commissionTotal = newOrder.commissionTotal;
            order.value.net = newOrder.net;
            order.value.paid = newOrder.paid;
            order.value.ballance = newOrder.ballance;
            order.value.discountPercent = newOrder.discountPercent;
            order.value.businessClientTotalAmount = newOrder.businessClientTotalAmount;
        }
        function findCustomer(order: OrderDetails, orderProductId: string, customerId: string) {
            for (const product of order.products) {
                for (const customer of product.customers) {
                    if (customer.orderProductId === orderProductId && customer.id === customerId) {
                        return customer;
                    }
                }
            }
            return null;
        }
        async function updateOrderProductCustomer(newOrder: OrderDetails, customer: OrderProductCustomerDetails) {
            updateOrderPrice(newOrder);
            const customerToUpdate = findCustomer(newOrder, customer.orderProductId, customer.id);
            if (customerToUpdate) {
                order.value.products.forEach(orderProduct => {
                    const foundCustomer = orderProduct.customers.find(
                        x => x.orderProductId == customer.orderProductId && x.id == customer.id
                    );
                    if (foundCustomer != null) {
                        foundCustomer.priceForOne = customerToUpdate.priceForOne;
                        foundCustomer.discountAmount = customerToUpdate.discountAmount;
                        foundCustomer.quantity = customerToUpdate.quantity;
                        foundCustomer.price = customerToUpdate.quantity * customerToUpdate.priceForOne - customerToUpdate.discountAmount;
                        foundCustomer.editMode = false;
                        orderProduct.price = orderProduct.customers.reduce((accumulator, object) => {
                            return accumulator + object.price;
                        }, 0);
                    }
                });
            }
        }
        async function saveOrderCustomerExistOrder(customer: OrderProductCustomerDetails) {
            swal.showLoading();
            const req: UpdateOrderProductCustomerRequest = {
                orderId: order.value.id,
                orderProductid: customer.orderProductId,
                orderProductCustomerId: customer.id,
                unitPrice: customer.editUnitPrice,
                discount: customer.editDiscountAmount,
                quantity: customer.editQuantity,
                slotId: customer.slot?.id ?? customer.slotId,
                discountChangedManually: discountChangedManually.value,
                ignoreQty: false
            };
            const apiPromise = api.UpdateOrderProductCustomer(req);
            await Q.delay(400);
            const response = await apiPromise;
            if (response.errorMessage && response.errorCode == 900) {
                const swalResult = await swal.fire({
                    icon: 'info',
                    text: response.errorMessage,
                    customClass: {
                        confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                        cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                    },
                    buttonsStyling: false,
                    showCancelButton: true,
                    confirmButtonText: t('editProductPage.cancelEdit.alertConfirm'),
                    cancelButtonText: t('editProductPage.cancelEdit.alertCancel')
                });
                if (!swalResult.isConfirmed) {
                    return;
                }
                await Q.delay(150);
                swal.showLoading();
                req.ignoreQty = true;
                const apiPromise2 = api.UpdateOrderProductCustomer(req);
                await Q.delay(400);
                const response2 = await apiPromise2;
                if (response2.errorMessage) {
                    swal.fire({ icon: 'error', text: response.errorMessage });
                    return;
                }
                if (response2.data) {
                    updateOrderProductCustomer(response2.data.order, customer);
                }
                swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: t('alert.order-updated'),
                    showConfirmButton: false,
                    timer: 1500
                });
                return;
            }
            if (response.errorMessage) {
                swal.fire({ icon: 'error', text: response.errorMessage });
                return;
            }
            if (response.data) {
                updateOrderProductCustomer(response.data.order, customer);
            }
            swal.fire({
                position: 'center',
                icon: 'success',
                title: t('alert.order-updated'),
                showConfirmButton: false,
                timer: 1500
            });
        }
        async function saveOrderCustomerNewOrder(customer: OrderProductCustomerDetails, productId: string) {
            const orderReq = _.cloneDeep(order.value);
            const productItem = orderReq.products.find(x => x.productId == productId);
            if (!productItem) {
                return;
            }
            const customerItem = productItem.customers.find(x => x.date == customer.date && x.id == customer.id);
            if (!customerItem) {
                return;
            }
            customerItem.priceForOne = customer.editUnitPrice;
            customerItem.discountAmount = customer.editDiscountAmount;
            customerItem.quantity = customer.editQuantity;
            swal.showLoading();
            const result = await apiCalculateOrder(orderReq);
            if (!result) {
                return;
            }
            const productResp = result.products.find(x => x.productId == productId);
            if (!productResp) {
                return;
            }
            const customerResp = productResp.customers.find(x => x.date == customer.date && x.id == customer.id);
            if (!customerResp) {
                return;
            }
            const basketrProduct = order.value.products.find(p => p.productId == productId);
            if (!basketrProduct) {
                return;
            }
            let orderCoupon: Coupon = new Coupon();
            if (customer.couponId) {
                orderCoupon = order.value.coupons.find(x => x.id == customer.couponId) ?? new Coupon();
                if (orderCoupon == undefined) {
                    return;
                }
            }
            order.value.commissionPercent = result.commissionPercent;
            order.value.totalDiscountAmount = result.discountAmount;
            order.value.priceTotal = result.priceTotal;
            order.value.totalToPay = result.totalToPay;
            order.value.gross = result.gross;
            order.value.commissionTotal = result.commissionTotal;
            order.value.net = result.net;
            order.value.paid = result.paid;
            order.value.ballance = result.ballance;
            order.value.discountPercent = result.discountPercent;

            if (customer.couponId) {
                const increasedQty = customerResp.discountAmount > customer.discountAmount;
                const differenceNumbers = Math.abs(customerResp.quantity - customer.quantity);
                const adjustment = increasedQty ? -1 : 1;

                if (orderCoupon.limitationType === CouponLimitationType.Ticket) {
                    orderCoupon.leftLimitationTotal += adjustment * differenceNumbers;
                } else {
                    let adjustmentValue = 0;

                    if (orderCoupon.discountPercent) {
                        adjustmentValue = ((differenceNumbers * customer.priceForOne) / 100) * orderCoupon.discountPercent;
                    } else if (orderCoupon.discountAmount) {
                        adjustmentValue = differenceNumbers * Number(orderCoupon.discountAmount);
                    } else if (orderCoupon.ticketFixedUnitPrice) {
                        adjustmentValue = differenceNumbers * Number(orderCoupon.ticketFixedUnitPrice);
                    } else if (orderCoupon.discountType == CouponDiscountType.NoDiscount) {
                        adjustmentValue = differenceNumbers * Number(customer.priceForOne);
                    }

                    orderCoupon.leftLimitationTotal += adjustment * adjustmentValue;
                }
            }
            customer.priceForOne = customerResp.priceForOne;
            customer.discountAmount = customerResp.discountAmount;
            customer.quantity = customerResp.quantity;
            customer.price = customerResp.price;
            basketrProduct.price = productResp.price;
            customer.editMode = false;
            swal.close();
        }
        async function saveOrderCustomer(customer: OrderProductCustomerDetails, productId: string) {
            if (
                customer.editDiscountAmount == customer.discountAmount &&
                customer.editQuantity == customer.quantity &&
                customer.editUnitPrice == customer.priceForOne
            ) {
                customer.editMode = false;
                return;
            }
            if (props.isNew) {
                await saveOrderCustomerNewOrder(customer, productId);
            } else {
                await saveOrderCustomerExistOrder(customer);
            }
        }
        async function removeProductCustomerInExistOrder(orderProductId: string, customerId: string, couponId: string | null) {
            if (couponId) {
                return;
            }
            swal.showLoading();
            const apiPromise = api.deleteOrderProductCustomer(props.id, orderProductId, customerId);
            await Q.delay(400);
            const apiResponse = await apiPromise;
            if (apiResponse.errorMessage) {
                swal.fire({ icon: 'error', text: apiResponse.errorMessage });
                return;
            }
            if (!apiResponse.data) {
                return;
            }
            order.value = apiResponse.data.order;
            updateOrderPrice(apiResponse.data.order);
            order.value.products.forEach((orderProduct, index) => {
                const findIndex = orderProduct.customers.findIndex(x => x.orderProductId == orderProductId && x.id == customerId);
                const existCustomer = findIndex >= 0;
                if (existCustomer && orderProduct.customers.length == 1) {
                    order.value.products.splice(index, 1);
                } else if (existCustomer) {
                    orderProduct.customers.splice(findIndex, 1);
                }
                if (existCustomer) {
                    orderProduct.price = orderProduct.customers.reduce((accumulator, object) => {
                        return accumulator + object.price;
                    }, 0);
                }
            });
            swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Product updated',
                showConfirmButton: false,
                timer: 1500
            });
        }
        async function calculateOrder() {
            swal.showLoading();
            const result = await apiCalculateOrder(order.value);
            if (result) {
                order.value.currencyId = result.currency;
                order.value.currencySymbol = result.currencySign;
                order.value.commissionPercent = result.commissionPercent;
                order.value.totalDiscountAmount = result.discountAmount;
                order.value.discountPercent = result.discountPercent;
                order.value.priceTotal = result.priceTotal;
                order.value.totalToPay = result.totalToPay;
                order.value.gross = result.gross;
                order.value.commissionTotal = result.commissionTotal;
                order.value.net = result.net;
                order.value.paid = result.paid;
                order.value.ballance = result.ballance;
                order.value.products = result.products;
                order.value.businessClientTotalAmount = result.businessClientTotalAmount;
                swal.close();
            }
        }
        async function removeProductCustomerInNewOrder(customer: OrderProductCustomerDetails, productId: string, customerIndex: number) {
            if (!props.isNew && order.value.products.length == 1 && order.value.products[0].customers.length == 1) {
                swal.fire({ icon: 'error', text: t('alert.order.delete-last-item') });
                return;
            }
            const productIndex = order.value.products.findIndex(x => x.productId == productId);
            if (productIndex < 0) {
                return;
            }
            if (customer.couponId) {
                const orderCoupon = order.value.coupons.find(x => x.id == customer.couponId) ?? new Coupon();
                if (orderCoupon.limitationType == CouponLimitationType.Ticket) {
                    orderCoupon.leftLimitationTotal += customer.quantity;
                } else {
                    if (orderCoupon.discountPercent) {
                        orderCoupon.leftLimitationTotal += ((customer.quantity * customer.priceForOne) / 100) * orderCoupon.discountPercent;
                    } else if (orderCoupon.discountAmount) {
                        orderCoupon.leftLimitationTotal += customer.quantity * Number(orderCoupon.discountAmount);
                    } else if (orderCoupon.ticketFixedUnitPrice) {
                        orderCoupon.leftLimitationTotal += customer.quantity * Number(orderCoupon.ticketFixedUnitPrice);
                    } else if (orderCoupon.discountType == CouponDiscountType.NoDiscount) {
                        orderCoupon.leftLimitationTotal += customer.quantity * Number(customer.priceForOne);
                    }
                }
            }
            if (order.value.products[productIndex].customers.length == 1) {
                order.value.products.splice(productIndex, 1);
            } else {
                order.value.products[productIndex].customers.splice(customerIndex, 1);
            }

            await calculateOrder();
        }
        async function removeProductCustomer(customer: OrderProductCustomerDetails, productId: string, customerIndex: number) {
            const swalResult = await swal.fire({
                icon: 'info',
                title: t('confirm.delete.orderProductCustomer'),
                customClass: {
                    confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: t('editProductPage.cancelEdit.alertConfirm'),
                cancelButtonText: t('editProductPage.cancelEdit.alertCancel')
            });
            if (!swalResult.isConfirmed) {
                return;
            }
            await Q.delay(200);
            if (props.isNew) {
                await removeProductCustomerInNewOrder(customer, productId, customerIndex);
            } else {
                await removeProductCustomerInExistOrder(customer.orderProductId, customer.id, customer.couponId);
            }
        }

        function customerEdit(customer: OrderProductCustomerDetails) {
            customer.editDiscountAmount = customer.discountAmount;
            customer.editQuantity = customer.quantity;
            customer.editUnitPrice = customer.priceForOne;
            customer.editTotalAmount = customer.price;
            customer.editMode = true;
        }

        async function addProduct() {
            const orderInStore = new OrderDetails();
            if (props.isNew) {
                if (!order.value.priceTypeId) {
                    return;
                }
            } else {
                orderInStore.id = order.value.id;
                orderInStore.currencyId = order.value.currencyId;
                orderInStore.priceTypeId = order.value.priceType.id;
                orderInStore.businessClientId = order.value.businessClientId;
                await saveStoreOrder(orderInStore);
            }
            router.push({ name: 'addorderproduct' });
        }

        async function saveOrder() {
            swal.showLoading();
            return await apiSaveOrder(order.value, message => {
                if (message?.includes('Invalid Mobile')) {
                    incorrectMobile.value = true;
                }
                if (message?.includes('Invalid CustomerEmail')) {
                    incorrectEmail.value = true;
                }
                return true;
            });
        }

        const submitForm = async (event: any) => {
            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
                swal.fire({
                    icon: 'warning',
                    title: t('alert.mandatoryfields'),
                    confirmButtonText: t('button.close')
                });
            } else {
                if (creatingBlocked.value) {
                    return;
                }
                creatingBlocked.value = true;
                const newOrder = await saveOrder();
                if (newOrder?.error) {
                    swal.fire({
                        icon: 'success',
                        title: newOrder?.error
                    });
                }
                if (newOrder && newOrder.orderId) {
                    await swal.fire({
                        icon: 'success',
                        title: t('alert.orderWasCreated'),
                        confirmButtonText: t('alert.goToOrder')
                    });
                    generalStore.commit('clearOrder');
                    router.push({ name: 'order', params: { id: newOrder.orderId } });
                }
                creatingBlocked.value = false;
            }
        };
        function back() {
            router.push({ name: 'orders' });
        }

        function checkHeader() {
            const result = document?.getElementById('order-details-title-client');
            const height = result?.offsetHeight;
            if (height && height > 80) {
                const title = document?.getElementById('order-details-title');
                title?.classList.add('client-name-not-fit');
            }
            const result2 = document?.getElementById('order-details-title-client');
            const height2 = result2?.offsetHeight;
            if (!height2) {
                return;
            }
            if (height2 > 110) {
                const title = document?.getElementById('order-details-title');
                title?.classList.remove('client-name-not-fit');
                title?.classList.add('client-name-not-fit2');
            }
        }
        function mobileNumberChanged() {
            incorrectMobile.value = false;
        }
        function emailChanged() {
            incorrectEmail.value = false;
        }

        async function onMountedAction() {
            swal.showLoading();
            if (props.isNew) {
                order.value = generalStore.getters.getNewOrder;
                if (!order.value) {
                    order.value = new OrderDetails();
                    order.value.newproduct = new OrderProductDetails();
                    generalStore.commit('setOrder', order.value);
                    const direct = channels.value.find(x => x.text.toLowerCase() == 'direct');
                    if (direct) {
                        order.value.channelId = direct.id;
                    }
                }
                channels.value = await generalStore.dispatch('getChannels');
                priceTypes.value = await generalStore.dispatch('getPriceTypes');
                const clientResult = await api.GetBussinesClientsByNewOrder();
                clients.value = clientResult.data?.businessClients || [];
                const direct = channels.value.find(x => x.text.toLowerCase() == 'direct');
                if (direct) {
                    order.value.channelId = direct.id;
                }
                priceTypeSelect.value = {
                    value: 0,
                    placeholder: '',
                    valueProp: 'id',
                    trackBy: 'text',
                    label: 'text',
                    required: true,
                    searchable: true,
                    options: priceTypes.value
                };
                businessClientSelect.value = {
                    placeholder: '',
                    valueProp: 'id',
                    trackBy: 'text',
                    label: 'name',
                    searchable: true,
                    options: clients.value
                };
                if (order.value.priceTypeId && order.value.products.length > 0) {
                    await calculateOrder();
                } else {
                    swal.close();
                }
            } else {
                const apiResult = await api.GetOrder(props.id);
                const windowViewPortScrolLY = ref(window.scrollY);
                await Q.delay(400);
                if (apiResult.data?.error) {
                    swal.fire({
                        icon: 'error',
                        text: apiResult.data?.error
                    });
                    return;
                }
                order.value = apiResult.data?.order || new OrderDetails();
                getMessages();
                // const input = document.getElementById('textMessage');
                // input?.addEventListener('keyup', function(event) {
                //     if (event.key === 'Enter') {
                //         event.preventDefault();
                //         sendMsg();
                //     }
                // });
                checkHeader();
                if (route.params.needScrollBottom) {
                    const container = document.getElementById('main-content');
                    if (container) {
                        container.scrollTop = container.scrollHeight;
                    }
                }
                const callback = (entries: any, observer: any) => {
                    entries.forEach((entry: any) => {
                        markMessagesAsRead();
                    });
                };
                const observer = new IntersectionObserver(callback, {
                    threshold: [0.5] // If 50% of the element is in the screen, we count it!
                    // Can change the thresholds based on your needs. The default is 0 - it'll run only when the element first comes into view
                });
                const div = document.getElementById('order-chat');
                if (div) {
                    observer.observe(div);
                }
                swal.close();
            }
            loaded.value = true;
        }

        const adjustTextareaHeight = () => {
            const textarea = document.getElementById('textMessage');
            if (textarea) {
                textarea.style.height = 'auto';
                textarea.style.height = `${textarea.scrollHeight}px`;
            }
        };

        const getFullNameOrderStatus = (status: string) => {
            switch (status) {
                case 'OK':
                    return 'order.title.full-name-confirmed';
                case 'RQ':
                    return 'order.title.full-name-requested';
                default:
                    return 'order.title.full-name-canceled';
            }
        };

        function assignEmployee(req: AssignEmployeeRequest) {
            const employeeId = req.employeeId;
            order.value.employeeIds.push(employeeId);
        }
        function unassignEmployee(req: AssignEmployeeRequest) {
            const employeeId = req.employeeId;
            order.value.employeeIds = order.value.employeeIds.filter(x => x != employeeId);
        }

        function goToOrdersList() {
            router.push({ name: 'orders' });
        }
        function onBussinessClientChange(businessClientId: number | null) {
            if (businessClientId == null) {
                order.value.leadCustomer.firstName = '';
                order.value.leadCustomer.lastName = '';
                order.value.leadCustomer.email = '';
                order.value.leadCustomer.mobile = '';
            } else {
                const client = clients.value.find(x => x.id == businessClientId);
                if (!client) {
                    return;
                }
                order.value.leadCustomer.firstName = client.contactFirstName;
                order.value.leadCustomer.lastName = client.contactLastName;
                order.value.leadCustomer.email = client.email;
                order.value.leadCustomer.mobile = client.mobile;
            }
        }

        function goToCrmCustomerDetails() {
            // TODO: BOOK - 2226 remove when ready for Production
            if (envIsProduction.value) {
                return;
            }
            if (order.value.crmCustomerId) {
                router.push({ name: 'crm-customer-details', params: { id: order.value.crmCustomerId } });
            }
        }

        async function calculateCustomerRow(
            customer: OrderProductCustomerDetails,
            productId: string,
            discountChangedManuallyParam = false
        ) {
            if (!customer.editDiscountAmount && !customer.editQuantity && !customer.editUnitPrice) {
                return;
            }
            discountChangedManually.value = discountChangedManuallyParam;
            swal.showLoading();
            const req: CalculateForUpdateOrderProductCustomerRequest = {
                priceTypeId: order.value.priceTypeId,
                productId: productId,
                orderProductCustomerId: customer.id,
                unitPrice: customer.editUnitPrice,
                discount: customer.editDiscountAmount,
                quantity: customer.editQuantity,
                slotId: customer.slot?.id ?? customer.slotId,
                discountChangedManually: discountChangedManually.value,
                startDateTime: customer.date,
                couponId: customer.couponId,
                ignoreQty: false
            };
            const response = await api.CalculateForUpdateOrderProductCustomer(req);
            if (response.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: response.errorMessage
                });
                return;
            }
            swal.close();
            if (!response.data) {
                return;
            }
            customer.editDiscountAmount = response.data.discount;
            customer.editQuantity = response.data.quantity;
            customer.editUnitPrice = response.data.unitPrice;
            customer.editTotalAmount = response.data.totalAmount;
        }

        function updateContractFormModalVisibility(newValue: boolean) {
            showContractFormModal.value = newValue;
        }

        async function contractFormModalConfirmed(req: SendContractFormRequest) {
            swal.showLoading();

            req.orderId = order.value.id;
            const response = await api.SendContractFormToClient(req);

            if (response.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: response.errorMessage
                });
                return;
            }
            else {
                swal.fire({
                    icon: 'success',
                    text: t('contractForm.sendCustomerContractFormEmail.success')
                });
                return;
            }
        }

        onMounted(onMountedAction);

        return {
            getFullNameOrderStatus,
            order,
            removemillis,
            getMinutes,
            EditOrder,
            actions,
            changeRemarks,
            message,
            sendMsg,
            changeOrderProductDate,
            OrderStatusColors,
            formatChatDate,
            chatTimerId,
            filterType,
            changeChatType,
            getNextMessages,
            actionsMenu,
            isActionsExpand,
            changeStatus,
            messagedMarked,
            isArrivalConfirmationExpand,
            arrivalConfirmation,
            onSaveArrivalConfirmation,
            removeProductCustomer,
            customerEdit,
            saveOrderCustomer,
            addProduct,
            submitForm,
            back,
            channels,
            loaded,
            orderProductExist,
            priceTypeSelect,
            priceTypes,
            isGroupPrice,
            mobileNumberChanged,
            emailChanged,
            incorrectMobile,
            incorrectEmail,
            businessClientSelect,
            calculateOrder,
            creatingBlocked,
            clients,
            adjustTextareaHeight,
            unassignEmployee,
            assignEmployee,
            goToOrdersList,
            orderType,
            canChangeUnitPrice,
            onBussinessClientChange,
            debounce: createDebounce(),
            calculateCustomerRow,
            showContractFormModal,
            updateContractFormModalVisibility,
            contractFormModalConfirmed,
            goToCrmCustomerDetails,
            // TODO: BOOK - 2226 remove when ready for Production
            envIsProduction
        };
    }
});
