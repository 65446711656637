
import { defineComponent, computed } from 'vue';

export default defineComponent({
    props: {
        modelValue: [String, Number],
        name: {
            type: String,
            required: true
        },
        value: [String, Number],
        label: String,
        tabIndex: String,
        disabled: Boolean,
        isSingleRow: Boolean,
        fw500OnSelect: Boolean
    },
    emits: ['update:modelValue', 'click'],
    setup(props, { emit }) {
        const guid = 'radio_' + kendo.guid();
        const checked = computed(() => props.modelValue === props.value);
        function onClick() {
            if (props.modelValue !== props.value) {
                emit('update:modelValue', props.value);
            }
            emit('click', props.value);
        }
        return {
            onClick,
            checked,
            guid
        };
    }
});
