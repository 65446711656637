
import { computed, defineComponent, ref } from 'vue';
import OverlayModal from '@/components/Calendar/OverlayModal.vue';
import Calendar from 'primevue/calendar';
import { generalStore } from '@/store';
import { InvoiceType } from '@/models/Enums';

export default defineComponent({
    components: {
        OverlayModal,
        Calendar
    },
    emits: ['onSubmit'],
    async setup(_, { emit }) {
        const isOpen = ref(false);
        const bulkInvoiceType = computed(() => generalStore.state.defaultProviderSettings?.bulkInvoiceType);

        const open = () => (isOpen.value = true);
        const close = () => (isOpen.value = false);
        const issueDate = ref<Date>(new Date());

        const onSubmitClick = () => {
            emit('onSubmit', issueDate.value);
        }

        return {
            isOpen,
            open,
            close,
            issueDate,
            onSubmitClick,
            bulkInvoiceType,
            InvoiceType
        };
    }
});
