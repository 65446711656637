import { SettingsProviderUsers, IdText, CurrencyRate, ProviderDocumentType } from '@/models/Interfaces';
import {
    CardType,
    CouponLimitationType,
    CouponType,
    CouponWhoFinances,
    ExternalAccountingSystem,
    PaymentType,
    SubCouponStatus,
    VatType,
    CouponDiscountType,
    InvoiceType
} from './Enums';
export class ProviderSettingsData {
    name: string;
    companyId: string;
    companyName: string;
    nameOfTheBusinessForInvoice: string;
    ytourId: string;
    lvCrmId: string;
    address: string;
    website: string;
    email: string;
    mobile: string;
    mainImageUrl: string;
    mainImage: string;
    policy: string;
    operationContactFullName: string;
    operationContactPhoneNumber: string;
    operationContactEmail: string;
    financeDepartmentFullName: string;
    financeDepartmentPhoneNumber: string;
    financeDepartmentEmail: string;
    description: string;
    longitude: number;
    latitude: number;
    users: SettingsProviderUsers[];
    smsEmailLanguageId: string;
    cCardMaxCountPayments: number | null;
    quantityPaymentsCredit: number | null;
    providerAccounts: ProviderAccountEntity[];
    profiles: ProviderProfilesEntity[];
    cashierMessages: CashierMessages[];
    orderSourceOptions: IdText[];
    commissionPercent: number;
    providerCurrencyRates: CurrencyRate[];
    autoPrintReceipt: boolean;
    providerDocumentTypes: ProviderDocumentType[];
    changesAndCancellationsPolicy: string;
    paymentConditions: string;
    bankAccountDetails: string;
    managerCode: string;

    constructor() {
        this.name = '';
        this.companyId = '';
        this.companyName = '';
        this.nameOfTheBusinessForInvoice = '';
        this.ytourId = '';
        this.lvCrmId = '';
        this.address = '';
        this.website = '';
        this.operationContactFullName = '';
        this.operationContactPhoneNumber = '';
        this.operationContactEmail = '';
        this.financeDepartmentFullName = '';
        this.financeDepartmentPhoneNumber = '';
        this.financeDepartmentEmail = '';
        this.email = '';
        this.mobile = '';
        this.mainImageUrl = '';
        this.mainImage = '';
        this.policy = '';
        this.description = '';
        this.longitude = 0;
        this.latitude = 0;
        this.users = [];
        this.smsEmailLanguageId = '';
        this.cCardMaxCountPayments = null;
        this.quantityPaymentsCredit = null;
        this.providerAccounts = [];
        this.profiles = [];
        this.cashierMessages = [];
        this.orderSourceOptions = [];
        this.commissionPercent = 0;
        this.providerCurrencyRates = [];
        this.autoPrintReceipt = false;
        this.providerDocumentTypes = [];
        this.changesAndCancellationsPolicy = '';
        this.paymentConditions = '';
        this.bankAccountDetails = '';
        this.managerCode = '';
    }
}

export class ProviderAddress {
    address = '';
    longitude = 0;
    latitude = 0;
}

export class ProviderAccountEntity {
    id = '';
    name = '';
    bankId = '';
    bankBranchNumber = '';
    accountNumber = '';
    accountExternal = '';
    providerAccountCurrencies: string[] = [];
}
export class ProviderProfilesEntity {
    active = true;
    cashierMaxChecks = 0;
    cashierMaxDicountPercent = 0;
    createDate = '';
    description = '';
    id = '';
    permissions = [];
    priceTypes = null;
    products = null;
    providerId = '';
}
export class CashierMessages {
    id = '';
    message = '';
    createDate: string | null = null;
}

export class AccountingSettings {
    externalAccountingSystem = ExternalAccountingSystem.Hashavshevet;
    externalAccountingSystemCode = '';
    perfomaInvoiceTitle = '';
    invoiceRemarks = '';
    isIncludePerfomaInvoiceInAccountDocsExport = false;
    invoiceType = InvoiceType.Debit
}

export class AccountingInfo {
    providerAccounts: ProviderAccountEntity[] = [];
    providerIncomeCards: ProviderIncomeCard[] = [];
    providerCashierCards: ProviderCashierCard[] = [];
    providerCreditCards: ProviderCreditCard[] = [];
    settings = new AccountingSettings();
    bulkInvoiceHistories: BulkInvoiceHistory[] = [];
}
export class CouponFilter {
    couponCode: string;
    couponName: string;
    fromDate: string | null;
    toDate: string | null;
    createFromDate: string | null;
    createToDate: string | null;
    clubId: string | null;
    productId: string | null;
    priceTypeId: string | null;
    discountAmount: number | null;
    currencyId: string;
    couponStatus?: boolean | null;
    keyword: string;
    orderBy: string;
    asc: boolean;
    pageIndex: number;
    fromBusinessClient: boolean;

    constructor() {
        this.couponCode = '';
        this.couponName = '';
        this.fromDate = null;
        this.toDate = null;
        this.createFromDate = null;
        this.createToDate = null;
        this.clubId = null;
        this.productId = null;
        this.priceTypeId = null;
        this.discountAmount = null;
        this.currencyId = '';
        this.couponStatus = null;
        this.keyword = '';
        this.orderBy = '';
        this.asc = false;
        this.pageIndex = 0;
        this.fromBusinessClient = false;
    }
}

export class ProviderIncomeCard {
    id = '';
    cardName = '';
    vatType = VatType.IncVat;
    accountingProgramCode = '';
    vatAccountNumber = '';
    exportParameter = '';
    currencyId = '';
}
export class ProviderCashierCard {
    id = '';
    cardName = '';
    paymentType: PaymentType[] = [];
    cardTypes: CardType[] = [];
    accountingProgramCode = '';
    currencyId = '';
}

export class BulkInvoiceHistory {
    id = '';
    startDate = '';
    endDate = '';
    invoicesQty = 0;
    invoicesAmount = 0;
    creditInvoicesQty = 0;
    creditInvoicesAmount = 0;
    status = 0;
    pdfUrl = '';
    xlsUrl = '';
}
export class ProviderCreditCard {
    id = '';
    cardType: CardType | null = null;
    firstFromDay: string | null = null;
    firstToDay: string | null = null;
    firstPaymentDay: string | null = null;
    secondFromDay: string | null = null;
    secondToDay: string | null = null;
    secondPaymentDay: string | null = null;
}

export class Club {
    id: number | null;
    originId: number;
    name: string;

    constructor() {
        this.id = null;
        this.originId = 0;
        this.name = '';
    }
}

export class Product {
    id: string;
    name: string;

    constructor() {
        this.id = '';
        this.name = '';
    }
}

export class CustomerType {
    id: string;
    name: string;

    constructor() {
        this.id = '';
        this.name = '';
    }
}

export class Currency {
    id: string;
    symbol: string;

    constructor() {
        this.id = '';
        this.symbol = '';
    }
}

export class Coupon {
    id: string;
    couponCode: string;
    couponName: string;
    ticketFixedUnitPrice: number | string | null;
    discountAmount: number | string | null;
    discountPercent: number | null;
    createdAt: string | null;
    validDateFrom: string;
    validDateTo: string;
    active: boolean;
    priceTypeId: string;
    couponType: CouponType;
    club: Club | null;
    product: Product;
    customerTypes: CustomerType[];
    currencyId: string;
    currencySymbol: string;
    limitationType: CouponLimitationType;
    limitationAmount: number | null;
    leftLimitationTotal: number;
    passed: boolean;
    subCoupons: SubCoupon[];
    discountType: CouponDiscountType;
    whoFinances: CouponWhoFinances;

    constructor() {
        this.id = '';
        this.couponCode = '';
        this.discountAmount = null;
        this.discountPercent = null;
        this.ticketFixedUnitPrice = null;
        this.couponName = '';
        this.createdAt = null;
        this.validDateFrom = '';
        this.validDateTo = '';
        this.active = true;
        this.club = new Club();
        this.product = new Product();
        this.customerTypes = [];
        this.currencyId = '';
        this.priceTypeId = '';
        this.limitationType = CouponLimitationType.Ticket;
        this.limitationAmount = null;
        this.passed = false;
        this.leftLimitationTotal = 0;
        this.currencySymbol = '';
        this.couponType = CouponType.BusinessClientAgreement;
        this.whoFinances = CouponWhoFinances.Discount;
        this.discountType = CouponDiscountType.Discount;
        this.subCoupons = [];
    }
}

export class SubCoupon {
    code: string;
    status: SubCouponStatus;

    constructor() {
        this.code = '';
        this.status = SubCouponStatus.Created;
    }
}
