
import { AccountingSettings, BulkInvoiceHistory } from '@/models/Provider';
import { defineComponent, PropType, ref, onMounted, onUnmounted, computed } from 'vue';
import { BulkInvoiceJobStatus, BulkInvoiceJobStatusColors } from '@/models/Enums';
import { api } from '@/services/Api';
import Q from 'q';
import swal from 'sweetalert2';
import RadioInput from '@/components/RadioInput.vue';

export default defineComponent({
    components: { RadioInput },
    props: {
        bulkInvoiceHistoriesProp: {
            type: Array as PropType<BulkInvoiceHistory[]>,
            required: true,
            default: () => []
        },
        settings: {
            type: AccountingSettings,
            required: true
        },
        isEdit: {
            type: Boolean,
            default: false
        }
    },
    async setup(props) {
        const hasNext = ref(true);
        const pageIndex = ref(0);
        const items = ref<BulkInvoiceHistory[]>([]);
        const setting = computed(() => props.settings);
        async function ListenerGetBulkInvoices() {
            if (!hasNext.value) {
                return;
            }
            swal.showLoading();
            const apiPromise = api.GetBulkInvoiceHistories(pageIndex.value);
            await Q.delay(400);
            const apiResult = await apiPromise;
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }
            if (!apiResult.data) {
                return;
            }
            apiResult.data.bulkInvoiceHistories.forEach(element => {
                items.value.push(element);
            });
            hasNext.value = apiResult.data.hasNext;
            pageIndex.value++;
            swal.close();
        }
        function handleScroll() {
            const app = document.getElementById('bulk-invoice-rows');
            if (app) {
                if (app.scrollTop + app.clientHeight >= app.scrollHeight) {
                    ListenerGetBulkInvoices();
                }
            }
        }
        const onMountedCall = async () => {
            items.value = JSON.parse(JSON.stringify(props.bulkInvoiceHistoriesProp));
            pageIndex.value++;
            const desktopGrid = document.getElementById('bulk-invoice-rows');
            if (desktopGrid) {
                desktopGrid.addEventListener('scroll', handleScroll);
            }
        };
        onMounted(onMountedCall);
        const onUnmountedCall = () => {
            const app = document.getElementById('bulk-invoice-rows');
            if (app) {
                app.removeEventListener('scroll', handleScroll);
            }
        };
        onUnmounted(onUnmountedCall);

        return {
            items,
            BulkInvoiceJobStatus,
            BulkInvoiceJobStatusColors,
            pageIndex,
            setting
        };
    }
});
